<template>
  <div class="w-3/5 p-4 pr-8 flex flex-col">
    <div class="flex items-center mb-2 h-12">
      <div class="module-title self-center">SMU</div>

      <div class="flex ml-auto">
        <div class="ml-2">
          <div
            @click="toggleSlSelectedItemsAll()"
            class="pill-gray h-full flex flex-col justify-center items-center"
          >
            <span :class="{ 'text-aux': all }">ALL</span>
          </div>
        </div>
        <div class="ml-2" v-for="(pill, k) in legendColors" :key="k">
          <div
            @click="toggleSlSelectedItems(k)"
            class="pill-gray"
            :style="getOpacity(pill, k)"
          >
            {{ pill.name }}
          </div>
        </div>
      </div>
    </div>

    <div class="h-full overflow-hidden relative">
      <loader :loading="loadingSMU" />

      <div v-if="!loadingSMU" class="h-full">
        <div class="scroll-gradient"></div>

        <div v-if="smuFiltered.length > 0" class="h-full overflow-auto">
          <div
            class="box h-20 mb-2 flex flex-row"
            v-for="(i, index) in smuFiltered"
            :key="index"
          >
            <div class="h-16 w-4 p-1 mr-2">
              <div
                class="h-full w-full rounded-full"
                :style="'background-color:' + legendColors[i.id_sl].color"
              ></div>
            </div>

            <div class="w-full overflow-hidden">
              <div class="text-xl mb-4">{{ i.name }}</div>
              <div class="flex">
                <div>
                  <div class="flex items-center">
                    <div class="h-2 w-2 rounded-full bg-orange mr-2"></div>
                    <div class="text-font-dark">Purchased</div>
                    <div class="text-lg ml-2 font-semibold">
                      {{ i.hour_purchased | numberFormat() }}h
                    </div>
                  </div>
                </div>
                <div class="mx-auto">
                  <div class="flex items-center">
                    <div class="h-2 w-2 rounded-full bg-blue mr-2"></div>
                    <div class="text-font-dark">Sold</div>
                    <div class="text-lg ml-2 font-semibold">
                      {{ i.hour_sold | numberFormat() }}h
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="h-full w-1/2 flex-none">
              <chart-col-compare-small
                chartId="salesCompare"
                :data="[i.amount_purchased, i.amount_sold]"
                :names="['Purchased', 'Sold']"
                :small="false"
                colors="purcashed"
              />
            </div>
          </div>
        </div>

        <div
          v-else-if="elementsSMU.length === 0"
          class="h-full flex flex-col justify-center items-center"
        >
          <span class="module-title text-font-gray">No data</span>
        </div>
        <div v-else class="h-full flex flex-col justify-center items-center">
          <span class="module-title text-font-gray">No SL selected</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { state, actions } from "@/store";
import ChartColCompareSmall from "../../../charts/ChartColCompareSmall.vue";

export default {
  components: { ChartColCompareSmall },
  name: "SMU",
  props: ["sortBy"],
  data() {
    return {
      elementsSMU: false,
      loadingSMU: false,
      all: false,
    };
  },
  methods: {
    load() {
      this.loadingSMU = true;
      this.axios
        .get("inter_intra/competency", { params: this.params })
        .then((response) => {
          this.loadingSMU = false;
          this.elementsSMU = response.data.data;
        });
    },
    getOpacity(pill, k) {
      if (this.slSelectedItems.includes(k)) {
        return (
          "color:" + pill.color + ";background-color:" + pill.color + "25;"
        );
      }
    },
    toggleSlSelectedItems(item) {
      actions.toggleSlSelectedItems(item);
    },
    toggleSlSelectedItemsAll() {
      // this.all = !this.all

      // for (let index = 0; index < Object.keys(this.legendColors).length; index++) {
      //     actions.toggleSlSelectedItems(Object.keys(this.legendColors)[index])
      // }

      this.all = !this.all;

      if (state.slSelectedItems.length == 9 || !this.all) {
        actions.clearSlSelectedItems();
      } else {
        for (
          let index = 0;
          index < Object.keys(this.legendColors).length;
          index++
        ) {
          actions.addSlSelectedItems(Object.keys(this.legendColors)[index]);
        }
      }
    },
  },
  computed: {
    legendColors() {
      return state.slLegendColors;
    },
    smuFiltered() {
      let arr = [];
      if (this.elementsSMU) {
        arr = this.elementsSMU.filter((item) => {
          return this.slSelectedItems.includes(item.id_sl);
        });
      }
      return _.orderBy(arr, [this.sortBy.value], [this.sortBy.direction]);
    },
    params() {
      return state.globalParams;
    },
    slSelectedItems() {
      return state.slSelectedItems;
    },
  },
  mounted() {
    this.load();
  },
  watch: {
    params() {
      this.load();
    },
  },
};
</script>
